import Seo from 'src/components/seo/Seo'
import AssessoriaImprensa from 'src/components/servicos/ServicesPage/AssessoriaImprensa'

function Page() {
  return (
    <>
      <Seo title="Assessoria de Imprensa | Decathlon" />
      <AssessoriaImprensa />
    </>
  )
}

export default Page
